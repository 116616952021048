import styles from "../../styles/LoginPage.module.scss";
import React from "react";
import { sendMetrics } from "../../utils/misc";
import Cookie from "mobx-cookie";

export default function SocialAuth() {

  const socialAuthClick = (social: string) => {
    if (social === 'vk') {
      sendMetrics("login_user_vk");
    } else if (social === 'odnokl') {
      sendMetrics("login_user_odnoklasniki");
    } else if (social === 'yandex') {
      sendMetrics("login_user_yandex");
    }

    const referralCode = sessionStorage.getItem('ReferralCode');

    if (referralCode) {
      let cookie = new Cookie("ReferralCode");
      cookie.set(referralCode, {expires: 365, domain: ".donorsearch.org" })
    }
  }

  return (
    <div className={styles.social_list}>
      <a
        className={styles.social_item + " " + styles.social_item_vk}
        href={"/api/auth/vkontakte/"}
      >
        <img
          onClick={() => socialAuthClick('vk')}
          src="/static/images/social/vk.svg"
          alt=""
        />
      </a>
      {/* <a
        className={styles.social_item + " " + styles.social_item_tw}
        href={"/api/auth/twitter/"}
      >
        <img
          onClick={() => {
            sendMetrics("login_user_twitter");
          }}
          src="/static/images/social/twitter.svg"
          alt=""
        />
      </a> */}
      {/*<a className={styles.social_item + ' ' + styles.social_item_fb} href={'/api/auth/facebook/'}>*/}
      {/*  <img onClick={() => {*/}
      {/*    sendMetrics('login_user_facebook')*/}
      {/*  }}*/}
      {/*       src="/static/images/social/fb.svg" alt=""/>*/}
      {/*</a>*/}
      <a
        className={styles.social_item + " " + styles.social_item_ok}
        href={"/api/auth/odnoklassniki/"}
      >
        <img
          onClick={() => socialAuthClick('odnokl')}
          src="/static/images/social/ok.svg"
          alt=""
        />
      </a>
      {/* <a
        className={styles.social_item + " " + styles.social_item_ok}
        href={"/api/auth/google/"}
      >
        <img
          onClick={() => {
            sendMetrics("login_user_google");
          }}
          src="/static/images/social/google.svg"
          alt=""
        />
      </a> */}
      <a
        className={styles.social_item + " " + styles.social_item_ok}
        href={"/api/auth/yandex/"}
      >
        <img
          onClick={() => socialAuthClick('yandex')}
          src="/static/images/social/yandex.svg"
          alt=""
        />
      </a>
      {/* <a
        className={styles.social_item + " " + styles.social_item_ok}
        href={"/api/auth/apple/"}
      >
        <img
          onClick={() => {
            sendMetrics("login_user_apple");
          }}
          src="/static/images/social/apple.svg"
          alt=""
        />
      </a> */}
    </div>
  );
}
