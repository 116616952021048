import React, { Component } from "react";
import { Router, withRouter } from "next/router";
import styles from "../styles/LoginPage.module.scss";
import { inject, observer } from "mobx-react";
import Link from "next/link";
import redirectTo from "../utils/redirectTo";
import SocialAuth from "../components/socialAuth";
import Cookie from "mobx-cookie";
import {
  getUserCityId,
  getValueForRegistrationTags,
  sendMetrics,
} from "../utils/misc";
import AuthStore from "../stores/AuthStore";
import SelectedCityStore from "../stores/SelectedCityStore";
import ResourseStore from "../stores/ResourseStore";
import Popup from "../components/popup 2/Popup";
import EyeIcon from "../components/ui/EyeIcon/EyeIcon";
import getCookie from "../hooks/getCookie";
import removeCookie from "../hooks/removeCookie";

interface LoginPageState {
  username: string;
  password: string;
  error: string;
  forgot: boolean;
  forgotInput: string;
  forgotAlert: string;
  forgotFinded: {
    message: string;
    type: string;
  };
  forgotInputCode: string;
  counter: number;
  counterPhone: number;
  resended: boolean;
  isPhoneSended: boolean;
  isEmailSended: boolean;
  next: string;
  isVisiblePass?: boolean;
}

@inject("authStore")
@inject("selectedCityStore")
@inject("citiesStore")
@observer
class LoginPage extends Component {
  state: LoginPageState;
  props: {
    authStore?: AuthStore;
    selectedCityStore?: SelectedCityStore;
    citiesStore?: ResourseStore;
    router?: Router;
  };

  constructor(props) {
    super(props);
    this.state = {
      username: undefined,
      password: undefined,
      error: undefined,
      forgot: false,
      forgotInput: "",
      forgotAlert: null,
      forgotFinded: null,
      forgotInputCode: "",
      counter: 0,
      counterPhone: 0,
      resended: false,
      isPhoneSended: false,
      isEmailSended: false,
      next: null,
      isVisiblePass: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { value, name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: undefined });
    let cookie = new Cookie("token");
    this.props.authStore
      .login(
        this.props.router.query.email
          ? `${this.props.router.query.email}`
          : this.state.username,
        this.state.password
      )
      .then(() => {
        getUserCityId(
          this.props.authStore.user,
          this.props.selectedCityStore,
          this.props.citiesStore
        ).then(() => {});
        removeCookie("ReferralCode")
        sessionStorage.removeItem("ReferralCode")
        cookie.set(this.props.authStore?.tokenStore?.token?.value, {
          expires: 365,
          domain: ".donorsearch.org",
        });
        // if(sessionStorage?.getItem('prevPath')?.startsWith('/fundraising')) {
        //   redirectTo("/fundraising");
        //   return;
        // }
        // if(this.props.router.query["utm_source"] === 'header') {
        //   redirectTo("/profile");
        //   return;
        // }
        // @ts-ignore
        if(getCookie('next') && this.props.router.query.next?.startsWith('/donate')) {
          redirectTo(`${getCookie('next')}`);
        }
        if (this.props.router.query.next) {
          redirectTo(this.props.router.query.next);
        } else if (this.props.router.query.email) {
          redirectTo("/profile");
        } else if (window?.location?.href?.includes('unity')) {
          // Редикект назад на юнити
          this.props.router.push(
            decodeURIComponent(window.location.href).split("?").pop()
          );
          // this.props.router.push("/profile/");
        } else if(getCookie('next')) {
          redirectTo(getCookie('next'));
        } else {
          this.props.router.push("/profile/");
        }
      })
      .catch((error) => {
        this.setState({ error: error.response.data.message });
      });
  }

  forgotPassword = () => {
    this.setState({
      forgot: true,
      forgotInput: this.state.username,
    });
  };

  backToLogin = () => {
    this.setState({
      username: undefined,
      password: undefined,
      error: undefined,
      forgot: false,
      forgotInput: "",
      forgotAlert: null,
      forgotFinded: null,
      forgotInputCode: "",
    });
  };

  forgotInputChange = (event) => {
    this.setState({
      forgotInput: event.target.value,
    });
  };

  forgotInputCodeChange = (event) => {
    this.setState({
      forgotInputCode: event.target.value,
    });
  };

  restoreAccess = (isSended = "") => {
    this.setState({
      isPhoneSended: this.state.isPhoneSended || isSended === "phone",
      isEmailSended: this.state.isEmailSended || isSended === "email",
    });
    this.props.authStore
      .recoverPassword(this.state.forgotInput)
      .then((response) => {
        this.setState({
          forgotAlert: response.data?.message,
          forgotFinded: {
            type: response.data?.login_type,
            message: response.data?.message,
          },
        });

        if (response.data?.login_type == "email" && this.state.counter <= 0) {
          this.setState({
            counter: 60,
          });

          const timer = setInterval(() => {
            if (this.state.counter <= 0) {
              clearInterval(timer);
            }
            this.setState({
              counter: this.state.counter - 1,
            });
          }, 1000);
        }

        if (
          response.data?.login_type == "phone" &&
          this.state.counterPhone <= 0
        ) {
          this.setState({
            counterPhone: 60,
          });

          const timerPhone = setInterval(() => {
            if (this.state.counterPhone <= 0) {
              clearInterval(timerPhone);
            }
            this.setState({
              counterPhone: this.state.counterPhone - 1,
            });
          }, 1000);
        }

        if (response.data?.login_type == "email" && this.state.counter <= 0) {
          this.setState({
            resended: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          forgotAlert: error.response.data.message,
        });
      });
  };

  restoreAccessPhone = () => {
    this.props.authStore
      .getTokenFromCode(this.state.forgotInputCode)
      .then((response) => redirectTo(`/reset/${response.data.token}`))
      .catch(() =>
        this.setState({
          forgotAlert: "Введен неверный код",
        })
      );
  };

  closeAlert = () => {
    this.setState({
      forgotAlert: null,
    });
  };

  toggleVisiblePass = () => {
    this.setState({
      isVisiblePass: !this.state.isVisiblePass,
    });
  };

  getPrevPath = () => {
    if (typeof window !== "undefined") {
      if (this.props.router.query.next !== window.sessionStorage.prevPath) {
        return this.props.router.query?.tag ?? window.sessionStorage.prevPath;
      } else {
        return this.props.router.query.next;
      }
    } else {
      return this.props.router.query.next;
    }
  };

  getRegLink = () => {
    if(this.props.router.query["utm_source"] === 'header') {
      return `/pre_sign_up/?next=/profile`;
    }

    if (this.state.next) {
      return `/pre_sign_up/?next=${this.state.next}`;
    } else {
      return `/pre_sign_up/?${
        typeof window !== "undefined" &&
        window.location.href.split("?").pop()
      }`
    }
  }

  componentDidMount() {
    const unsignedLink = Object.keys(this.props.router.query)[0];
    const next = new Cookie("next");
    const cookie = new Cookie("RegistrationTag");

    if (
      decodeURIComponent(window.location.href)
        ?.split("?")
        ?.pop()
        ?.split("/")[2] === "unity.redcross.ru"
    ) {
      cookie.set("RedCross", { expires: 365, domain: ".donorsearch.org" });
    }

    if(this.props.router.query["referral-code"]) {
      const refCode: string = String(this.props.router.query["referral-code"]);
      sessionStorage.setItem('ReferralCode', refCode);
    }

    if (
      this.props.router.query.next &&
      this.props.router.query.next !== "undefined"
    ) {
      // @ts-ignore
      if(this.props.router.query.next?.startsWith('/donate')) {
        const url = location.search;
        const match = url.match(/&next=([^&]*(&.*)*)/);
        const nextValue = match ? match[1] : null;

        next.set(nextValue as string);
        // @ts-ignore
        this.setState({ next: encodeURIComponent(nextValue) });
      } else {
        next.set(this.props.router.query.next as string);
        // @ts-ignore
        this.setState({ next: encodeURIComponent(this.props.router.query.next) });
      }
    } else if (unsignedLink?.substring(0, 4) === "http") {
      next.set(unsignedLink as string);
      // @ts-ignore
      this.setState({ next: encodeURIComponent(unsignedLink) });
    }

    getValueForRegistrationTags(this.getPrevPath());

    if(window?.location?.search?.includes('restore-access')) {
      this.forgotPassword()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<LoginPageState>,
    snapshot?: any
  ) {
    if (prevState.next !== this.state?.next) {
      const prev =
        this.props.router.query?.tag ??
        getValueForRegistrationTags(this.getPrevPath());
      let cookie = new Cookie("RegistrationTag");
      if (prev !== cookie.value && prev) {
        if (prev === "MainPage" && this.props.router.query?.tag) {
          cookie.set(this.props.router.query?.tag, {
            expires: 365,
            domain: ".donorsearch.org",
          });
        } else {
          cookie.set(prev, { expires: 365, domain: ".donorsearch.org" });
        }
      }
    }
  }

  render() {
    return (
      <>
        {this.props.router.query.email && (
          <Popup>
            <form
              className={styles.Form}
              id="new_user"
              onSubmit={this.handleSubmit}
              noValidate
            >
              <h2 className={styles.FormTitle}>
                Добро пожаловать<br></br> в личный кабинет{" "}
                <span className={styles.FormTitleSpan}>DonorSearch</span>!
              </h2>
              <p className={styles.FormText}>
                Для того, чтобы войти в личный кабинет донора, введите,
                пожалуйста, пароль из письма, которое вы получили на почту
                <span className={styles.FormTextEmail}>
                  {" "}
                  {this.props.router.query.email}{" "}
                </span>
                после выпуска бонусной карты DonorSearch в приложении «Кошелёк».
              </p>
              <div className={styles.FormMain}>
                <div className={styles.FormInputs}>
                  <div>
                    <label
                      className={`${styles.FormLabel} ${styles.FormLabelLogin}`}
                    >
                      Логин
                    </label>
                    <input
                      className={`${styles.input} ${styles.FormInputDefault}`}
                      autoFocus={true}
                      placeholder="Номер телефона или Email"
                      type="email"
                      name="username"
                      id="username"
                      autoComplete="off"
                      defaultValue={this.props.router.query.email}
                      disabled
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <label className={styles.FormLabel}>Пароль</label>
                    <input
                      className={`${styles.input} ${
                        this.state.error ? styles.FormInputError : ""
                      }`}
                      autoComplete="off"
                      placeholder="Пароль"
                      type={this.state.isVisiblePass ? "text" : "password"}
                      name="password"
                      id="password"
                      onChange={this.handleChange}
                    />
                    {this.state.error && (
                      <p className={styles.FormError}>Введен неверный пароль</p>
                    )}
                    <EyeIcon onClick={this.toggleVisiblePass} />
                  </div>
                </div>
                <input
                  type="submit"
                  name="login"
                  value="Войти"
                  onClick={() => {
                    sendMetrics("login_user_pass");
                  }}
                  className={`${styles.button} ${styles.FormBtn}`}
                  data-disable-with="Войти"
                />
                <div className={styles.FormFooter}>
                  <span>
                    Не можете войти? Напишите нам на{" "}
                    <a href="mailto:support@donorsearch.org">
                      support@donorsearch.org
                    </a>
                  </span>
                </div>
              </div>
            </form>
          </Popup>
        )}
        <div style={{ paddingTop: "32px" }}>
          <title>Авторизоваться на DonorSearch</title>
          {/* Блок предупржедение */}
          {/* <div className={styles.warnBlock}>
            <p>С 1 декабря 2023 мы были вынуждены убрать возможность авторизации на платформе DonorSearch через аккаунты Google и Apple ID. <br /><br />Это новое требование законодательства РФ. Поэтому для того, чтобы вы могли авторизоваться в DonorSearch, необходимо установить пароль по ссылке <a href='/login/?restore-access'>https://donorsearch.org/login/?restore-access</a> <br /><br />Если возникли трудности, напишите нам на почту <a href="mailto:support@donorsearch.org">support@donorsearch.org</a></p>
          </div> */}
          {!this.state.forgot && (
            <div className={styles.login}>
              <div
                className={styles.login_nav + " d-flex justify-content-center"}
              >
                <div className={styles.login_nav_item}>
                  <Link
                    href={
                      this.state.next
                        ? "/login/?next=" + this.state.next
                        : "/login/"
                    }
                  >
                    <a className={styles.login_nav_item_active}>Вход</a>
                  </Link>
                </div>
                <div className={styles.login_nav_item}>
                  <Link
                    href={
                      this.getRegLink()
                    }
                  >
                    <a>Регистрация</a>
                  </Link>
                </div>
              </div>

              <div className={styles.input_block}>
                <div className={styles.small_text}>
                  <span>Войдите с помощью социальных сетей</span>
                </div>
                <SocialAuth />
                <div className={styles.small_text}>
                  Email или по номеру телефона
                </div>
                {this.state.error && (
                  <div className={styles.alert}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.error,
                      }}
                    />
                  </div>
                )}
                <form
                  className=""
                  id="new_user"
                  onSubmit={this.handleSubmit}
                  noValidate
                >
                  <div>
                    <input
                      className={styles.input}
                      autoFocus={true}
                      placeholder="Номер телефона или Email"
                      type="email"
                      name="username"
                      id="username"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <input
                      className={styles.input}
                      autoComplete="off"
                      placeholder="Пароль"
                      type="password"
                      name="password"
                      id="password"
                      onChange={this.handleChange}
                    />
                    <span
                      className={styles.forgot}
                      onClick={this.forgotPassword}
                    >
                      Забыли?
                    </span>
                  </div>
                  <input
                    type="submit"
                    name="login"
                    value="Войти"
                    onClick={() => {
                      sendMetrics("login_user_pass");
                    }}
                    className={styles.button}
                    data-disable-with="Войти"
                  />
                </form>
              </div>
              <div className={styles.forgotBlockBottom}>
                <span>
                  Не можете войти? Напишите нам на{" "}
                  <a href="mailto:support@donorsearch.org">
                    support@donorsearch.org
                  </a>
                </span>
              </div>
            </div>
          )}
          {this.state.forgot && (
            <div className={styles.forgotBlock}>
              <div className={styles.forgotBlockHeader}>
                <span onClick={this.backToLogin}>
                  <i className="zmdi zmdi-arrow-left mr-2" />
                  Назад
                </span>
              </div>
              <div className={styles.forgotBlockMain}>
                <h1>Восстановление доступа</h1>
                {this.state.forgotAlert && (
                  <div className={styles.forgotBlockError}>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.state.forgotAlert,
                        }}
                      />
                      <p onClick={this.closeAlert}>×</p>
                    </span>
                  </div>
                )}
                {!this.state.forgotFinded && (
                  <div className={styles.forgotBlockInputs}>
                    <span className={styles.forgotBlockMainPrompt}>
                      {
                        window.location.search.includes('restore-access') ?
                        'Email'
                        :
                        'Телефон или Email'
                      }
                    </span>
                    <label htmlFor="forgotInputCode">
                      <input
                        type="text"
                        id={"forgotInput"}
                        value={this.state.forgotInput}
                        placeholder={`${window.location.search.includes('restore-access') ? 'Введите email' : 'Введите телефон или Email'}`}
                        onChange={this.forgotInputChange}
                      />
                    </label>
                    <button onClick={() => this.restoreAccess("")}>
                      Восстановить доступ
                    </button>
                  </div>
                )}
                {this.state.forgotFinded?.type === "phone" && (
                  <div className={styles.forgotBlockInputs}>
                    <span className={styles.forgotBlockMainPromptCode}>
                      Код СМС подтверждения
                    </span>
                    <label htmlFor="forgotInput">
                      <input
                        type="text"
                        id={"forgotInputCode"}
                        value={this.state.forgotInputCode}
                        placeholder={"Введите код подтверждения"}
                        onChange={this.forgotInputCodeChange}
                      />
                    </label>
                    {!this.state.isPhoneSended && (
                      <>
                        {this.state.counterPhone > 0 ? (
                          <div className={styles.forgotBlockInputsAgain}>
                            Выслать код повторно через {this.state.counterPhone}{" "}
                            сек.
                          </div>
                        ) : (
                          <div
                            className={styles.forgotBlockInputsAgainClick}
                            onClick={() => this.restoreAccess("phone")}
                          >
                            Выслать код повторно
                          </div>
                        )}
                      </>
                    )}
                    <button onClick={this.restoreAccessPhone}>
                      Продолжить
                    </button>
                  </div>
                )}
                {this.state.forgotFinded?.type === "email" &&
                  !this.state.isEmailSended && (
                    <div>
                      {this.state.counter > 0 && (
                        <div>
                          Если письмо не пришло, вы сможете отправить повторное
                          через {this.state.counter} сек.
                        </div>
                      )}
                      {this.state.counter <= 0 && !this.state.resended && (
                        <button onClick={() => this.restoreAccess("email")}>
                          Восстановить доступ
                        </button>
                      )}
                    </div>
                  )}
              </div>
              <div className={styles.forgotBlockBottom}>
                <span>
                  Не можете войти? Напишите нам на{" "}
                  <a href="mailto:support@donorsearch.org">
                    support@donorsearch.org
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(LoginPage);
